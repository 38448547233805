import { Card, IconButton, Typography } from '@mui/material'
import HeaderGestorConfigs from '../../../../utils/headerGestorConfigs'
import StatusEstabelecimento from '../../../../utils/statusEstabelecimento'

export default function FichaSolicitacaoImportacao({ solicitacaoImportacao }) {
    return (
        <Card style={{ padding: 20, position: 'relative' }}>
            <HeaderGestorConfigs
                menu={false}
                configArea={''}
                title={'SOLICITAÇÃO'}
            />

            {(solicitacaoImportacao?.status === 'Aprovado' && solicitacaoImportacao?.dataAprovacao) &&
                <IconButton size="large" onClick={() => ''} style={{ position: 'absolute', right: 0 }}>
                    <img src="/img/icones/licenciamentoicon.svg" alt="autor.." width={35} />
                </IconButton>
            }

            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Tipo de Certificado:</strong> {solicitacaoImportacao?.tipoCertificado ?? '####'}</Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Endereço:</strong> {solicitacaoImportacao?.endereco ?? '####'} </Typography>
            {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Solicitado:</strong> {solicitacaoImportacao?.tipo?.designacao ?? '####'}</Typography> */}
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nº Fact. Proforma:</strong> {solicitacaoImportacao?.numFaturaPro ?? '####'}</Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>País de origem:</strong> {solicitacaoImportacao?.paisOrigem ?? '####'}</Typography>
            <StatusEstabelecimento status={solicitacaoImportacao.status} />
        </Card>
    )
}
