
import { useState } from "react";
import { makeStyles } from "@material-ui/core"
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { FormControl, InputLabel, MenuItem, Select, Card, Container, Grid, Typography, TextField } from "@mui/material";
import { CountryDropdown } from 'react-country-region-selector';
import { useNavigate } from "react-router-dom";
import InputDocUpload from "../../../../../utils/inputFileUpload/inputDocUpload";

const useStyles = makeStyles((theme) => ({

    inputPais: {
        padding: 18,
        borderRadius: 4,
        borderColor: '#e1e1e1',
        width: '100%'
    },

    inputFile: {
        border: '1px dashed',
        borderRadius: 5,
        marginTop: 10
    }
}))

export default function FormSoliciteImportacao() {
    const navigate = useNavigate()
    const classes = useStyles()
    const [solicitacao, setSolicitacao] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [open, setOpen] = useState(false)

    async function HandleChangeFile(file, name) {
        setSolicitacao((prevCadastro) => ({ ...prevCadastro, [name]: file }))
    }

    function handleChange(e) {
        e.target.files ?
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
            :
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value })
    }

    async function createSolicitacaoImportacao() {
        const formData = new FormData();

        formData.append("tipoCertificado", solicitacao?.tipoCertificado)
        formData.append("endereco", solicitacao?.endereco)
        formData.append("numFatura", solicitacao?.nFatura)
        formData.append("numFaturaPro", solicitacao?.fatProforma)
        formData.append("paisOrigem", solicitacao?.paisOrigem)
        formData.append("numeroAutorizacao", solicitacao?.numeroAutorizacao)
        formData.append("importadorId", '6515659218f5cec218410fe4')

        // Docs
        formData.append("solicitacao", solicitacao?.solicitacao ?? '') // 1.	Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora
        formData.append("autorizacaoExportacao", solicitacao?.autorizacaoExportacao ?? '')  // 2.	Autorização de exportação emitida pelas entidades competentes do país de origem
        formData.append("parecerDNSP", solicitacao?.parecerDNSP ?? '') //3.	Parecer da Direcção Nacional de Saúde Pública para a importação de antituberculosos
        formData.append("parecerINLS", solicitacao?.parecerINLS ?? '') //4.	Parecer do Instituto Nacional de Luta contra a Sida para a importação de anti-retrovirais
        formData.append("copiaValidacao", solicitacao?.copiaValidacao ?? '') // 5.	Cópia da validação emitida pelo Instituto Nacional de Investigação em Saúde, para a importação de Testes de Diagnóstico Rápido para rastreio e diagnóstico de doenças infecciosas
        formData.append("cartaOriginal", solicitacao?.cartaOriginal ?? '') // 6.	Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes
        formData.append("faturaPro", solicitacao?.faturaPro ?? '') // 7.	Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola);
        formData.append("certificadoBPF", solicitacao?.certificadoBPF ?? '') // 8.	Certificado de Boas Práticas de Fabrico (GMP)
        formData.append("copiaFI", solicitacao?.copiaFI ?? '') // 9.	Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa

        setOpen(true)
        seterrorMessage('')
        setMessage('')

        await api.post('/solicitacao-importacao/register', formData)
            .then(res => {
                setMessage(res.data.msg)
                navigate(`/solicitar/importacao/add-item/${res.data.solicitacaoImportacao._id}`)
                // console.log(res)
            }).catch(error => {
                seterrorMessage(error.response.data.message)

                console.log(error)
            }).finally(() => setOpen(false))
    }

    return (
        <div>
            <RegisterAccess page={'solicitação de importação'} />
            <LoadingBackdrop open={open} text={'Enviando solicitação.!'} />

            <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
                <HeaderSession title='SOLICITAÇÃO DE IMPORTAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}

                <Card style={{ padding: 10 }}>

                    <Grid container>
                        <Grid xs={12} md item m >

                            <FormControl fullWidth >
                                <InputLabel id="tipoCertificado">Tipo Certificado</InputLabel>
                                <Select
                                    labelId="tipoCertificado"
                                    id="tipoCertificado"
                                    label="Tipo Certificado"
                                    name="tipoCertificado"
                                    value={solicitacao.tipoCertificado ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='Productos Especiais'>Productos Especiais</MenuItem>
                                    <MenuItem value='Psicotrópicos'>Psicotrópicos</MenuItem>
                                    <MenuItem value='Estupefacientes'>Estupefacientes</MenuItem>
                                    <MenuItem value='Precursores'>Precursores</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Solicitante-Importador (Nº de autorizacao)"
                                fullWidth
                                name="numeroAutorizacao"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>

                    <Grid container>

                        <Grid xs={12} md item m>
                            <CountryDropdown
                                className={classes.inputPais}
                                value={solicitacao?.paisOrigem ?? ''}
                                // onChange={handleChange}
                                onChange={(pais) => setSolicitacao({ ...solicitacao, paisOrigem: pais })}
                                defaultOptionLabel='Pais De Origem'
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Endereço"
                                fullWidth
                                name="endereco"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº da Factura (Emolumento Armed)"
                                fullWidth
                                name="nFatura"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº da Fatura Proforma"
                                fullWidth
                                name="fatProforma"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>


                    {/* ANEXAR DOCUMENTOS */}

                    <Grid container>
                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'solicitacao'}
                                inputTitle={'Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'autorizacaoExportacao'}
                                inputTitle={'Autorização de exportação emitida pelas entidades competentes do país de origem'}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'parecerDNSP'}
                                inputTitle={'Parecer da Direcção Nacional de Saúde Pública para a importação de antituberculosos'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>

                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'parecerINLS'}
                                inputTitle={'Parecer do Instituto Nacional de Luta contra a Sida para a importação de anti-retrovirais'}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'copiaValidacao'}
                                inputTitle={'Cópia da validação emitida pelo Instituto Nacional de Investigação em Saúde, para a importação de Testes de Diagnóstico Rápido para rastreio e diagnóstico de doenças infecciosas'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'cartaOriginal'}
                                inputTitle={'Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes'}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'faturaPro'}
                                inputTitle={'Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'certificadoBPF'}
                                inputTitle={'Certificado de Boas Práticas de Fabrico (GMP)'}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <InputDocUpload
                                onFileSelect={HandleChangeFile}
                                inputName={'copiaFI'}
                                inputTitle={'Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>

                        </Grid>
                    </Grid>

                    <Typography marginTop={5} align="center" variant="subtitle2" >
                        Clica em seguite para adicionar os itens da factura que pretende importar.
                    </Typography>

                    <br />
                    <ButtonLeft title={'Seguinte'} funcao={createSolicitacaoImportacao} disabled={false} />
                </Card>

            </Container>
            <br />
        </div>
    );
}
