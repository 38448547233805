
import { Button, Paper, TextField, Typography,Box} from '@mui/material';
import { useState } from 'react';
import api from '../../../services/api';
import LoadingBackdrop from "../../../load/loadingBackdrop";
import { useSelector } from 'react-redux';
import ImageBannerEdit from './imageBannerEdit';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// import RegisterAccess from '../../../utils/registerAccess';

const CreateBanner = (props) => {
    const [banner, setNoticia] = useState(props?.banner)
    const [open, setOpen] = useState(false)
    const user = useSelector(state => state.account.user);
    const [Preview, setPreview] = useState(false)

    // console.log(banner)

    function HandleChange(e) {
        e.target.files ?
            setNoticia({ ...banner, [e.target.name]: e.target.files[0] })
            :
            setNoticia({ ...banner, [e.target.name]: e.target.value })
    }

    async function HandleCreateBanner() {
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('titulo', banner?.titulo ?? '');
        formData.append('descricao', banner?.descricao ?? '');
        formData.append('imagem', banner?.imagem);
        formData.append('status', 'Activo');

        setOpen(true)
        props.seterrorMessage('')
        props.setMessage('')

        props?.banner ?
            // EDITANDO UMA NOTICIA
            await api.put('/carousel/edit', { ...banner, 'userId': user._id, 'bannerId': props.banner._id })
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setEditarBanner(false)
                }).catch(error => {

                    // props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                    // console.log(error)
                })
            :
            // CRIANDO UMA NOVA NOTICIA 
            await api.post('/carousel/create', formData)
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setCreateBanner(false)
                }).catch(error => {

                    props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                    // console.log(error)
                })
    }


    return (
        <>
            {/* <RegisterAccess page={'Criar banner'}/> */}
            <LoadingBackdrop open={open} text={'A criar Banner. Aguarde!'} />

            {!Preview ?
                <>
                    <div>
                        <Typography align='center' variant="subtitle1" style={{ fontSize: 20 }}>
                            {props.title}
                        </Typography>
                        <br />

                        {props?.banner
                            ?
                            <ImageBannerEdit foto={props.banner.imagem} bannerId={props.banner._id} setEditarBanner={props.setEditarBanner} />
                            :
                            <Paper style={{ border: '1px dashed #3e3d3f', position:'relative' }}>
                                <label htmlFor="imagem" style={{ cursor: 'pointer'}}>
                                    {banner?.imagem ?
                                        <div>
                                            <Box style={{ display: 'flex', alignItems: 'center', height: '100%', position: 'absolute', padding: '3rem'}} sx={{ width: { xs: '90x', md: '60%' }, fontSize: { xs: '1.5rem', md: '2.5rem' } }}>
                                                <p>
                                                    <strong> {banner?.titulo} </strong>
                                                    <br />
                                                    {banner?.descricao}
                                                </p>
                                            </Box>
                                            <img src={props?.banner ? props.banner.imagem : URL.createObjectURL(banner.imagem)} alt="Imagem" width="100%" title="alterar imagem.." />
                                        </div>
                                        :
                                        <Typography align="center" variant='body2' style={{ padding: 20 }}>
                                            Imagem: 1000x650px - Resolução: 72px
                                        </Typography>
                                    }
                                    <input accept="image/png, image/jpg, image/jpeg" type="file" name="imagem" id="imagem" style={{ display: 'none' }} onChange={HandleChange} />
                                </label>
                            </Paper>
                        }

                        <TextField
                            margin='dense'
                            type="text"
                            label="Titulo"
                            fullWidth
                            size="small"
                            name="titulo"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={banner?.titulo}
                        />

                        <TextField
                            margin='dense'
                            type="text"
                            label="Descrição"
                            fullWidth
                            size="small"
                            name="descricao"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={banner?.subTitulo}
                        />
                        <br />
                    </div>

                    <div align='center'>
                        {/* <br />
                        <FormControl>
                            <RadioGroup
                                row
                                style={{ alignItems: 'center' }}
                            >
                                <FormLabel style={{ marginRight: 10 }} id="status">Status </FormLabel>
                                <FormControlLabel name="status" value={'Activo'} control={<Radio />} label="Publicar" onClick={HandleChange} />
                                <FormControlLabel name="status" value={'Inactivo'} control={<Radio />} label="Não Publicar" onClick={HandleChange} />
                            </RadioGroup>
                        </FormControl> */}
                        <br />
                        <br />
                        <Button color='error' style={{ marginRight: 10 }} variant="contained" onClick={() => {props.setCreateBanner(false) }}>
                            Cancelar
                        </Button>
                        {/* <Button style={{ marginRight: 10 }} variant="contained" onClick={() => setPreview(true)}>
                            Preview
                        </Button> */}
                        <Button disabled={(!banner?.imagem)} style={{ backgroundColor: '#85287e' }} variant="contained" onClick={HandleCreateBanner}>
                            Salvar
                        </Button>
                    </div>
                </>

                :
                //  PREVIEW 
                <div >
                    <Typography variant="subtitle1" style={{ fontSize: 20, margin: 5 }}>
                        <IconButton size='small' color='error' style={{ marginRight: 10 }} onClick={() => setPreview(false)}>
                            <CloseIcon />
                        </IconButton>
                        Preview
                    </Typography>
                    {/* <Divider /> */}

                    {/* <ViewNoticia preview={banner} criacao={true} /> */}
                </div>
            }
        </>
    );
}

export default CreateBanner;
