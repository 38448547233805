import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import './drawer.css'
import Alert from '@mui/material/Alert';

interface FileUploaderProps {
    onFileSelect: (file: File | null, name: string) => void;
    inputName: string;
    inputTitle: string;
    maxSizeInMB?: number;
}

const InputDocUpload: React.FC<FileUploaderProps> = ({ onFileSelect, inputName, inputTitle, maxSizeInMB = 2 }) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);


    const validateFile = (file: File): boolean => {
        if (file.type !== 'application/pdf') {
            setError('Por favor, selecione apenas arquivos PDF.');
            return false;
        }

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > maxSizeInMB) {
            setError(`O arquivo excede o tamanho máximo de ${maxSizeInMB}MB.`);
            return false;
        }

        setError(null);
        return true;
    };

    const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLFormElement>) => { 
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file, inputName);
            } else {
                onFileSelect(null, '');
            }
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file, inputName);
            } else {
                e.target.value = '';
                onFileSelect(null, '');
            }

        }
    };
    return (
        <div className="file-uploader-container">
            <form
                className="file-upload-form"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    id={inputName}
                    type="file"
                    name='testeFile'
                    accept="application/pdf"
                    onChange={handleFileSelect}
                    className="file-upload-input"
                />

                <label
                    htmlFor={inputName}
                    className={`file-upload-label ${dragActive ? 'drag-active' : ''}`}
                >
                    <div className="upload-icon-container">

                        <p className="upload-text" style={{ display: selectedFile ? 'none' : '' }}>
                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                            <br />
                            Arraste e solte aqui <b> {inputTitle} </b> ou clique para carregar 
                            <br />
                            <small> (máx. {maxSizeInMB}mb)</small>
                            {error && <small className="error-message">{error}</small>}
                        </p>
                        <p className="upload-text txt-name" style={{ display: !selectedFile ? 'none' : '' }} >
                            <Alert severity="success">
                                {selectedFile?.name}
                            </Alert>
                        </p>
                    </div>
                </label>
            </form>
        </div>
    )
}

export default InputDocUpload
